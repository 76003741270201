import { graphql,Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../../styles/global.css'
import React from 'react'
import Navbar from '../../components/Navbar/Navbar';

export default function index({data}) {
    const blogs = data.blogs.nodes;
    return (
        <div>
            <Navbar color="black" />
            <div className="every-blogs">
                {blogs.map(blog=>(
                   <div  key={blog.id}>
                       <div className="single-blog">
                          <div>
                           <h3 className="bold">{blog.frontmatter.title}</h3>
                           <div>
                               <span className="thin right-margin">{blog.frontmatter.date}</span>
                               <span className="thin">{blog.frontmatter.readMin}</span>
                            </div>
                            <div>
                                <p className="blog-details regular">
                                    {blog.frontmatter.details}
                                </p>
                            </div>
                            <Link to={"/blogs/"+blog.frontmatter.url}>
                                <button className="readmore">Read more..</button>
                            </Link>
                           </div>
                           <div>
                           <img src={blog.frontmatter.Banner.childImageSharp.gatsbyImageData.images.fallback.src} alt="banner" />
                           </div>
                       </div>
                   </div> 
                ))

                }
            </div>
        </div>
    )
}


export const query = graphql`
query BlogDetails {
    blogs: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          title
          url
          readMin
          date
          details
          Banner {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: NO_CHANGE)
            }
          }
        }
        id
      }
    }
  }
`
